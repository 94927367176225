import { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import DownloadIcon from '@mui/icons-material/Download'

import CloseIcon from '@mui/icons-material/Close'

import { Link as LinkI } from './Navbar'

import { useRefContext } from 'context/RefContext'

interface DrawerI {
	open: boolean
	setOpen: (open: boolean) => void
}


const Drawer: FC<DrawerI> = ({open, setOpen}) => {

	const { t, i18n } = useTranslation()

	const { HomeRef, GameModeRef, ContactRef } = useRefContext()

	const Links: LinkI[] = useMemo(() => {
		return [
			{
				text: t('Home'),
				icon: <HomeOutlinedIcon/>,
				onClick: () => (HomeRef?.current as any).scrollIntoView({behavior: 'smooth'})
			},
			{
				text: t('Game modes'),
				icon: <DescriptionOutlinedIcon/>,
				onClick: () => (GameModeRef?.current as any).scrollIntoView({behavior: 'smooth'})
			},
			{
				text: t('Download now'),
				primary: true,
				icon: <DownloadIcon/>,
				onClick: () => (ContactRef?.current as any).scrollIntoView({behavior: 'smooth'})
			}
		]
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language])

	return (
		<SwipeableDrawer anchor='top' open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} onClick={() => setOpen(false)}>
			<Box display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center' margin={3} sx={{cursor: 'pointer'}}>
				<CloseIcon onClick={() => setOpen(false)} sx={{color: theme => theme.palette.primary.main}}/>
			</Box>
			<List>
				{
					Links.map((link) => (
						<ListItem key={link.text} disablePadding onClick={link.onClick}>
							<ListItemButton sx={{ bgcolor: link.primary ? theme => theme.palette.primary.main : undefined, color: link.primary ? theme => theme.palette.common.white : undefined, justifyContent: 'center', width: '100%'}}>
								<Box display='flex' alignItems='center' paddingY={1}>
									<Box marginRight={2} display='flex' alignItems='center'>
										{link.icon}
									</Box>
									{link.text}
								</Box>
							</ListItemButton>
						</ListItem>
					))
				}
			</List>
		</SwipeableDrawer>
	)
}

export default Drawer
