import { FC } from "react";
import { Box, Typography } from "@mui/material";


interface StatProps {
	image: any
	number: string
	label: string
}

const Stat:FC<StatProps> = ({image, number, label}) => {

	return (
		<Box display='flex' flexDirection='column' alignItems='center' gap={2}>
			{image}
			<Box display='flex' flexDirection='column' alignItems='center'>
				<Typography variant='h2'>
					{number}
				</Typography>
				<Typography fontWeight={100} variant='body2' textTransform='uppercase'>
					{label}
				</Typography>
			</Box>
		</Box>
	)
}

export default Stat