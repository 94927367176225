import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from "i18next-browser-languagedetector";

i18n
	.use(detector)
	.use(initReactI18next)
	.init(
		{
			returnEmptyString: false,
			fallbackLng: 'en',
			keySeparator: '`'
		}
	)

export default i18n
