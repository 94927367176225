import React from 'react'

import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import App from './components/App'

import { RefContextProvider } from 'context/RefContext'

import theme from './theme'

import './index.css'
import './i18n'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<RefContextProvider>
				<CssBaseline />
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</RefContextProvider>
		</ThemeProvider>
	</React.StrictMode>
)
