import { FC } from 'react'

import { isMobile } from 'react-device-detect'

import Box from '@mui/material/Box'
import apps from 'static/images/apps.png'
import dart from 'static/images/dart.png'
import star from 'static/images/star.svg'
import target from 'static/images/target.svg'

import { useRefContext } from 'context/RefContext'
import { Container, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SocialButton, Stat } from 'components/common'

const HomePage: FC = () => {

	const { t } = useTranslation()

	const { HomeRef, ContactRef, GameModeRef } = useRefContext()

	return (
		<Box>
			<Box ref={HomeRef} maxHeight='80vh' bgcolor={theme => theme.palette.primary.main} padding={8} paddingBottom={12} marginBottom={12}>
				<Container maxWidth='lg' sx={{ display: 'flex', flexDirection: {xs: 'column', md:'row'}, justifyContent: 'center', alignItems: 'center', gap: 8 }}>
					<Box display='flex' flexDirection='column' alignItems='start' width={{xs: '100%', md: '50%'}}>
						<Typography variant='h1' sx={{ color: theme => theme.palette.common.white }}>
							{t('The #1 Darts Scoreboard App')}
						</Typography>
						<Typography  color={theme => theme.palette.common.white } variant='body2' style={{paddingBottom: 22}}>
							{t('Darts - Simple Scoreboard is a free app designed to keep your game sharp. Effortlessly track your scores and focus on hitting your next bullseye!')}
						</Typography>
						<Box display='flex' gap={2} flexWrap='wrap' justifyContent='center'>
							<SocialButton variant='ios'/>
							<SocialButton variant='android'/>
						</Box>
					</Box>
					<img src={apps} alt="Apps" style={{zIndex: 2, objectFit: 'contain', maxWidth: '80%', maxHeight: isMobile ? '50%' : '100%'}}/>
				</Container>
			</Box>

			<Box maxHeight='70vh' bgcolor={theme => theme.palette.common.white} padding={8} paddingBottom={12} marginBottom={12}>
				<Container maxWidth='lg' sx={{ display: 'flex', flexDirection: {xs: 'column', md:'row'}, justifyContent: 'space-evenly', alignItems: 'center', gap: 8 }}>
					<Stat
						image={<img src={dart} alt="Dart" style={{zIndex: 2, objectFit: 'contain', width: '70px'}}/>}
						number='350,000'
						label={t('Darts thorwn')}
					/>
					<Stat
						image={<img src={target} alt="Target" style={{zIndex: 2, objectFit: 'contain', width: '70px'}}/>}
						number='10,000'
						label={t('Game played')}
					/>
					<Stat
						image={<img src={star} alt="Star" style={{zIndex: 2, objectFit: 'contain', width: '70px'}}/>}
						number='100'
						label={t('5 Star Reviews')}
					/>
				</Container>
			</Box>

			<Container maxWidth='lg' sx={{ display: 'flex', flexDirection: {xs: 'column', md:'row'}, justifyContent: 'space-evenly', alignItems: 'center', gap: 8 }}>
				<Divider sx={{width: '100%'}}/>
			</Container>

			<Box ref={GameModeRef} bgcolor={theme => theme.palette.common.white} padding={8} paddingBottom={12} marginBottom={12}>
				<Container maxWidth='lg' sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
					<Typography variant='h1' sx={{ color: theme => theme.palette.primary.main, textAlign: 'center' }}>
					{t('Game Modes')}
					</Typography>

					<Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} gap={4} justifyContent='space-evenly' alignItems='center'>
						<Box display='flex' flexDirection='column' alignItems='center' width={{ xs: '100%', md: '30%' }}>
							<Typography variant='h4' sx={{ color: theme => theme.palette.primary.main }}>
							{t('X01')}
							</Typography>
							<Typography variant='body2' sx={{ textAlign: 'center', color: theme => theme.palette.text.primary }}>
							{t('Play the classic X01 game, starting from 301, 501, or 701. Aim to reach exactly zero to win.')}
							</Typography>
						</Box>

						<Box display='flex' flexDirection='column' alignItems='center' width={{ xs: '100%', md: '30%' }}>
							<Typography variant='h4' sx={{ color: theme => theme.palette.primary.main }}>
							{t('Cricket')}
							</Typography>
							<Typography variant='body2' sx={{ textAlign: 'center', color: theme => theme.palette.text.primary }}>
							{t('Strategically close the numbers 15 through 20 and the bullseye before your opponent, while keeping your score lower.')}
							</Typography>
						</Box>

						<Box display='flex' flexDirection='column' alignItems='center' width={{ xs: '100%', md: '30%' }}>
							<Typography variant='h4' sx={{ color: theme => theme.palette.primary.main }}>
							{t('Around the World')}
							</Typography>
							<Typography variant='body2' sx={{ textAlign: 'center', color: theme => theme.palette.text.primary }}>
							{t('Hit the numbers sequentially from 1 to 20, then finish with the bullseye. Perfect for improving precision!')}
							</Typography>
						</Box>
					</Box>

					<Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} gap={4} justifyContent='space-evenly' alignItems='center'>
						<Box display='flex' flexDirection='column' alignItems='center' width={{ xs: '100%', md: '30%' }}>
							<Typography variant='h4' sx={{ color: theme => theme.palette.primary.main }}>
							{t('Battle Royale')}
							</Typography>
							<Typography variant='body2' sx={{ textAlign: 'center', color: theme => theme.palette.text.primary }}>
							{t('Take out your opponents by hitting their designated territories. Last player standing wins.')}
							</Typography>
						</Box>

						<Box display='flex' flexDirection='column' alignItems='center' width={{ xs: '100%', md: '30%' }}>
							<Typography variant='h4' sx={{ color: theme => theme.palette.primary.main }}>
							{t('Sudden Death')}
							</Typography>
							<Typography variant='body2' sx={{ textAlign: 'center', color: theme => theme.palette.text.primary }}>
							{t('Survival mode! Players lose a life after each round if they have the lowest score. Lose all lives, and you\'re out!')}
							</Typography>
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' width={{ xs: '100%', md: '30%' }}>
							<Typography variant='h4' sx={{ color: theme => theme.palette.primary.main }}>
							{t('And many more')}
							</Typography>
							<Typography variant='body2' sx={{ textAlign: 'center', color: theme => theme.palette.text.primary }}>
							{t('Stay tuned for exciting new game modes to keep your dart games fresh and fun!')}
							</Typography>
						</Box>
					</Box>
				</Container>
			</Box>

			<Box ref={ContactRef} minHeight='70vh' bgcolor={theme => theme.palette.primary.main} padding={8} display='flex' flexDirection='column' gap={6} justifyContent='center' alignItems='center'>
				<Box display='flex' gap={2} flexDirection='column' flexWrap='wrap' justifyContent='center'>
				<Typography textAlign='center' variant='h1' fontWeight='bold' color={theme => theme.palette.common.white}>{t('Hit the Bullseye, Track Every Throw!')}</Typography>
					<Typography textAlign='center' variant='h4' fontWeight='normal' color={theme => theme.palette.common.white}>{t('Download Darts Simple Scoreboard and enjoy playing darts !')}</Typography>
				</Box>
				<Box display='flex' gap={2} flexWrap='wrap' justifyContent='center'>
					<SocialButton variant='ios'/>
					<SocialButton variant='android'/>
				</Box>
			</Box>
		</Box>
	)
}

export default HomePage
