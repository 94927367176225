import { FC } from 'react'

import googleplay from 'static/images/googleplay.png'
import appstore from 'static/images/appstore.png'
import { Link } from '@mui/material'

const SocialButton: FC<{variant?: 'android' | 'ios'}> = ({variant}) => {

	let href = 'https://apps.apple.com/us/app/darts-simple-scoreboard/id6476447091'
	let src = appstore

	if (variant === 'android') {
		href = 'https://play.google.com/store/apps/details?id=com.tommyriquet.dartz'
		src = googleplay
	}

	return (
		<Link href={href} target='_blank'>
			<img src={src} alt='Get the app' width={200} />
		</Link>
	)
}

export default SocialButton
