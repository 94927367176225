import { FC } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import theme from 'theme'

interface NavbarLinkI {
	text: string
	primary?: boolean
	onClick: () => void
}

const NavbarLink: FC<NavbarLinkI> = ( { text, primary, onClick } ) => {

	return (
		<Box
			onClick={onClick}
			marginX={2}
			paddingX={1}
			color={theme.palette.primary.main}
			display='inline-block'
			position='relative'
			sx={{
				cursor: 'pointer',
			}}
		>
			<Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
				<Typography
					variant='h6'
					sx={{
							backgroundColor: primary ? theme.palette.primary.main : undefined,
							padding: primary ? 2 : undefined,
							color: primary ? theme.palette.common.white : theme.palette.primary.contrastText,
							userSelect: 'none'
						}}
					>
					{text}
				</Typography>
			</Box>
		</Box>
	)
}

export default NavbarLink
