import { FC, Fragment, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import MenuIcon from '@mui/icons-material/Menu'
import DownloadIcon from '@mui/icons-material/Download';
import { isMobile } from 'react-device-detect'

import NavbarLink from './NavbarLink'
import Drawer from './Drawer'

import { useRefContext } from 'context/RefContext'

import logo from 'static/icons/assets/logo.png'


export interface Link {
	text: string
	icon: JSX.Element
	primary?: boolean
	onClick: () => void
}


const Navbar : FC = () => {

	const { t, i18n } = useTranslation()

	const [ open, setOpen ] = useState(false)

	const { GameModeRef, ContactRef } = useRefContext()

	const Links: Link[] = useMemo(() => {
		return [
			{
				text: t('Home'),
				icon: <HomeOutlinedIcon/>,
				onClick: () => window.scrollTo({top: 0, behavior: 'smooth'})
			},
			{
				text: t('Game modes'),
				icon: <SettingsEthernetIcon/>,
				onClick: () => (GameModeRef?.current as any).scrollIntoView({behavior: 'smooth'})
			},
			{
				primary: true,
				text: t('Download now'),
				icon: <DownloadIcon/>,
				onClick: () => (ContactRef?.current as any).scrollIntoView({behavior: 'smooth'})
			}
		]
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language])

	if ( isMobile ){
		return (
			<Fragment>
				<Drawer open={open} setOpen={setOpen}/>
				<Box display='flex' flexDirection='row' justifyContent='end' alignItems='center' padding={5}>
					<Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' onClick={() => setOpen(true)}>
						<MenuIcon sx={{color: theme => theme.palette.primary.main}}/>
					</Box>
				</Box>
			</Fragment>
		)
	}

	return (
		<Box
			display='flex'
			flexDirection='row'
			justifyContent='space-between'
			alignItems='center'
			height={60}
			position='sticky'
			top={0}
			paddingX={3}
			sx={{
				backgroundColor: theme => theme.palette.common.white,
				zIndex: 1000
			}}
		>
			<img src={logo} alt='logo' width={40} height={40} style={{cursor: 'pointer'}} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}/>
			<Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
				{
					Links.map( ( link, index ) => {
						return (
							<NavbarLink key={index} {...link}/>
						)}
					)
				}
			</Box>
		</Box>
	)
}

export default Navbar
